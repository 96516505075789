
import { defineComponent, ref } from 'vue';
import ErpList from '@/components/ErpList/ErpList.vue';
import useListData from './components/useListData';
import useVisible from '@/hooks/useVisible';
import {
  ajaxLoading,
  erpConfirm,
  errorMessage,
  exportFile,
  loadPage,
} from '@/utils/publicMethods';
import router from '@/router';
import ErpDrawer from '@/components/ErpDrawer/ErpDrawer.vue';
import { RouteLocationRaw } from 'vue-router';
import useTimer from '@/hooks/useTimer';
export default defineComponent({
  name: 'Orderspot',
  components: { ErpList, ErpDrawer },
  props: {},
  setup() {
    // const Instance = (getCurrentInstance() as any);
    const { startTimer, timeStatus } = useTimer();
    const { columnList, tableRef } = useListData();
    const { visible, showVisible } = useVisible();
    const {
      visible: statisticsVisible,
      showVisible: showStatisticsVisible,
    } = useVisible();
    const rowId = ref('');
    const customParams: any = ref();

    const refreshTable = (status = false) => {
      tableRef.value.refreshTable(status);
    };
    // 查看详情
    const rowClick = (row: { orderId: any; version: any }) => {
      loadPage('OrderSpotDetail', {
        orderId: row.orderId,
        version: row.version,
        name: 'Orderspot',
      });
    };

    // 关闭弹层
    const closeVisible = (status: any) => {
      visible.value = false;
      statisticsVisible.value = false;
      // 解除重复提交
      ajaxLoading.unLock();
      if (status) {
        refreshTable();
      }
    };

    const getLoadPage = (url: RouteLocationRaw) => {
      router.push(url);
    };
    // 筛选导出
    const exportFileByHead = () => {
      // 30秒内只能点击一次方法，
      if (timeStatus.value === true) {
        erpConfirm('确定导出吗？').then(() => {
          startTimer();
          exportFile({
            type: 'POST',
            url: '/malicrm/order/exportXhdcOrderList',
            data: tableRef.value.getQueryParams(),
          });
        });
      } else {
        errorMessage('导出的点击间隔请勿小于30秒');
      }
    };

    return {
      exportFileByHead,
      customParams,
      columnList,
      tableRef,
      refreshTable,
      visible,
      showVisible,
      closeVisible,
      rowClick,
      getLoadPage,
      statisticsVisible,
      showStatisticsVisible,

      rowId,
    };
  },
});
