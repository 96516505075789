import { ErpTableOptions } from '@/types/type';
import { defineComponent, ref } from 'vue';
export default () => {
  const tableRef = ref();
  const columnList: ErpTableOptions[] = [
    {
      label: '销售合同号',
      prop: 'businessContractNo', // 属性
      minWidth: 160,
      type: 'input',
      fixed: 'left',
      value: '',
    },
    {
      label: '国家',
      prop: 'country',
      propDesc: 'countryDisplay',
      minWidth: 120,
      type: 'input',
      value: '',
    },
    {
      label: '品名',
      prop: 'nameCn',
      propDesc: 'nameCnMerged',
      minWidth: 142,
      type: 'input',
      value: '',
    },
    {
      label: '客户',
      prop: 'customerName',
      minWidth: 220,
      type: 'input',
      value: '',
    },
    {
      label: '签约主体',
      prop: 'contractPrincipalName',
      minWidth: 150,
      type: 'input',
      value: '',
    },
    {
      label: '冷库',
      prop: 'storageName',
      minWidth: 100,
      type: 'input',
      value: '',
    },
    {
      label: '柜号',
      prop: 'containerNo',
      minWidth: 100,
      type: 'input',
      value: '',
    },
    {
      label: '订单状态',
      prop: 'status',
      propDesc: 'statusDisplay',
      minWidth: 128,
      type: 'multiSelect',
      options: 'order_status',
      value: [1, 4],
    },
    {
      label: '合同重量合计(kg)',
      prop: 'contractWeightSumDisplay',
      minWidth: 183,
      type: 'number',
      filterProp: ['contractWeightSumMin', 'contractWeightSumMax'],
      value: [],
    },
    {
      label: '合同货值合计',
      prop: 'contractValueSumDisplay',
      minWidth: 158,
      type: 'number',
      filterProp: ['contractValueSumMin', 'contractValueSumMax'],
      value: [],
    },
    {
      label: '入库重量合计(kg)',
      prop: 'instoreWeightSumDisplay',
      minWidth: 183,
      type: 'number',
      filterProp: ['instoreWeightSumMin', 'instoreWeightSumMax'],
      value: [],
    },
    {
      label: '实际货值合计',
      prop: 'actualValueSumDisplay',
      minWidth: 158,
      type: 'number',
      value: [],
      filterProp: ['actualValueSumMin', 'actualValueSumMax'],
    },
    {
      label: '销售合同到期日',
      prop: 'businessContractEndDate',
      minWidth: 170,
      type: 'time',
      component: defineComponent({
        template: `
        <div :style="{color:row.color?row.color:'#303133'}">{{filterName(row.businessContractEndDate)}}</div>
        `,
        props: ['row'],
      }),
      filterProp: ['businessContractStartDate', 'businessContractEndDate'],
      value: [],
    },
    {
      label: '提货状态',
      prop: 'pickStatus',
      propDesc: 'pickStatusDesc',
      minWidth: 129,
      type: 'multiSelect',
      options: 'pick_status',
      value: [],
    },
    {
      label: '签约日期',
      prop: 'signDate',
      filterProp: ['signStartDate', 'signEndDate'],
      minWidth: 174,
      type: 'time',
      value: [],
    },
    {
      label: '完结日期',
      prop: 'finishDate',
      filterProp: ['finishStartDate', 'finishEndDate'],
      minWidth: 174,
      type: 'time',
      value: [],
    },
    {
      label: '单证跟单员',
      prop: 'userNameDoc',
      propDesc: 'userNameDocMerged',
      minWidth: 160,
      type: 'input',
      value: '',
    },
    {
      label: '资管跟单员',
      prop: 'userNameFund',
      propDesc: 'userNameFundMerged',
      minWidth: 160,
      type: 'input',
      value: '',
    },
    {
      label: '订单创建时间',
      prop: 'createdTime',
      minWidth: 174,
      type: 'time',
      filterProp: ['createdTimeStart', 'createdTimeEnd'],
      value: [],
    },
  ];
  return { columnList, tableRef };
};

//
